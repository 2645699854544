import logo from './logo.svg';
import './App.css';
import React, { useRef, useState } from 'react';
import SignIn from './components/SignIn'
import ChatRoom from './components/ChatRoom'
import SignOut from './components/SignOut'

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const firebaseApp = initializeApp({
  apiKey: "AIzaSyAdDPuEdu9P9hz6LtFTyKyoRTjf6Ig6kdY",
  authDomain: "erias-chat-app.firebaseapp.com",
  projectId: "erias-chat-app",
  storageBucket: "erias-chat-app.appspot.com",
  messagingSenderId: "922360635226",
  appId: "1:922360635226:web:f8d43e7a6332f097171d63",
  measurementId: "G-H7PCVRDK0X"
})

const firestore = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider);
}

const signOutUser = () => {
    signOut(auth);
  }

function App() {
  const [user, setUser] = useState('');

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // const uid = user.uid;
      setUser(user);
    } else {
      setUser('');
    }
  });


  return (
    <div className="App">
      <header className="App-header">
        <SignOut signOut={signOutUser} />
      </header>

      <section>
        {user ? <ChatRoom firestore={firestore} currentUser={user} /> : <SignIn signInWithGoogle={signInWithGoogle} />}
      </section>
    </div>
  );
}



export default App;
