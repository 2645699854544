import React, { useRef, useState } from 'react';
import ChatMessage from './ChatMessage'
import firebase from 'firebase/compat/app';
import { query, orderBy, limit, collection, addDoc, doc, setDoc, getDocs, serverTimestamp, onSnapshot } from "firebase/firestore";
import { useCollectionData } from 'react-firebase-hooks/firestore';



function ChatRoom({ firestore, currentUser }) {

    const messagesRef = collection(firestore, 'messages');
    const q = query(messagesRef, orderBy('createdAt'), limit(2500));
    const [messages] = useCollectionData(q, {idField: 'id'});

    const [formValue, setFormValue] = useState('');

    const sendMessage = async(e) => {
        e.preventDefault();

        setFormValue('');

        const { uid, photoURL } = currentUser;

        const docRef = await addDoc(collection(firestore, 'messages'), {
            text: formValue,
            createdAt: serverTimestamp(),
            uid, 
            photoURL
        });

        scrollDown.current.scrollIntoView({behavior: 'smooth'});
    }
    
    const scrollDownPage = onSnapshot(q, (querySnapshot) => {
        // gets called often, other technique is proferred
        scrollDown.current?.scrollIntoView({behavior: 'smooth'});
      });

    const scrollDown = useRef();
    


    return (
        <>
        <main>
            {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} user={currentUser} />)}
            <div ref={scrollDown}></div>
        </main>

        <form onSubmit={sendMessage}>
            <input value={formValue} onChange={(e) => setFormValue(e.target.value)} />

            <button type='submit'>Send</button>

        </form>
        </>
    )
}

export default ChatRoom
