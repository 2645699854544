
function ChatMessage({ user, message }) {
    const { text, uid, photoURL } = message;

    const messageClass = uid === user.uid ? 'sent' : 'received';

    return (
        <div className={`message ${messageClass}`}>
            <img src={photoURL} />
            <p>{text}</p>
        </div>
    )
}

export default ChatMessage
